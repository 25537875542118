import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Première infraction d'alcool au volant au Québec – Conséquences"
            description="Vous avez été accusé pour alcool au volant, mais vous en êtes à votre première infraction? Avocats Alcool au Volant vous expose toutes les conséquences auxquelles vous faites face."
            fr={undefined}
            lawType="criminal"
            specialtyText="spécialisé en alcool au volant">
            <p>
                Vous avez été intercepté au volant d’un véhicule par la police
                alors que vous aviez consommé de l’alcool?{' '}
            </p>
            <p>
                Même s’il s’agit peut-être de votre{' '}
                <strong>première infraction </strong>d’alcool au volant et qu’il
                est possible que cela soit une simple erreur de parcours,{' '}
                <Link to="https://avocatsalcoolauvolant.ca/consequences-alcool-volant/">
                    les conséquences{' '}
                </Link>
                auxquelles vous ferez face sont sérieuses.
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Que faire si vous êtes accusé d’alcool au volant pour la première fois?"
                    alt=""
                />
            </p>
            <p>
                En effet, même si cette infraction est relativement fréquente au
                Québec, il n’en reste pas moins que le législateur a imposé de
                lourdes sanctions aux personnes reconnues coupables d’avoir
                conduit sous l’influence de l’alcool.
            </p>
            <p>
                Ainsi, si vous vous retrouvez dans une telle situation, vous
                pourriez faire face à de nombreuses sanctions qui vont varier en
                fonction de la gravité des circonstances dans lesquelles vous
                vous trouviez au moment où vous avez été intercepté.{' '}
            </p>
            <p>
                De plus, si vous en êtes à votre première infraction, les
                sanctions seront beaucoup moins graves que s’il s’agit de votre
                deuxième ou votre troisième infraction. Dans ce cas, vos
                sanctions seront de plus en plus graves.
            </p>
            <p>
                <strong>
                    Dans cet article, Avocats Alcool au Volant vous indique les
                    conséquences lorsque vous en êtes à votre première
                    infraction d’alcool au volant!
                </strong>
            </p>
            <h2>L’infraction d’alcool au volant – Que faut-il savoir?</h2>
            <p>
                Lorsque vous conduisez un véhicule automobile sur les voies
                publiques, vous avez un certain nombre d’obligations à respecter
                et, dans le cas inverse, vous ferez face à certaines
                infractions, notamment la{' '}
                <strong>conduite avec les facultés affaiblies</strong>.{' '}
            </p>
            <p>
                Plus précisément, l’infraction d’alcool au volant aura lieu
                lorsque vous êtes intercepté par un policier alors que vous êtes
                au volant en état d’ébriété. Si le policier soupçonne que vous
                êtes dans cet état, il pourra vous demander de passer des tests
                et de souffler dans un alcootest.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Bon à savoir!</strong> Pour être reconnu
                            coupable d’alcool au volant, il est souvent question
                            du fameux « .08 ». Cela signifie que l’alcootest
                            révèle que vous avez 80 mg d’alcool pour 100 ml de
                            sang.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                En revanche, si vous êtes en dessous de ce barème, cela ne veut
                pas dire pour autant que vous ne risquez aucune conséquence. En
                effet, l’accusation de conduite avec les facultés affaiblies n’a
                pas ces barèmes et donc, si le policier considère que vous
                n’êtes pas en mesure de conduire, il pourra prendre les mesures
                appropriées.{' '}
            </p>
            <p>
                Ainsi, si vous atteignez ce barème, vous serez automatiquement
                accusé d’avoir commis une infraction d’alcool au volant. Ainsi,
                dès votre <strong>première accusation</strong>, il vous sera
                possible de faire face à quelques conséquences.{' '}
            </p>
            <p>
                Dès que cette accusation est portée contre vous, il vous sera
                possible de plaider coupable ou non coupable. À ce stade, il est
                évidemment recommandé de faire appel à un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/avocat-criminel-alcool-volant/">
                    avocat spécialisé dans les affaires d’alcool au volant
                </Link>{' '}
                afin de pouvoir entreprendre les démarches.{' '}
            </p>
            <h2>
                Quelles sont les conséquences d’une première infraction d’alcool
                au volant?
            </h2>
            <p>
                Lorsque vous êtes arrêtée pour alcool au volant, il est possible
                que votre premier réflexe soit de penser aux procédures
                judiciaires ainsi qu’aux conséquences criminelles qu’un juge
                pourrait prononcer à votre égard.
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Quelles sont les conséquences de l’alcool au volant?"
                    alt=""
                />
            </p>
            <p>
                Cependant, bien que ces sanctions puissent être particulièrement
                sévères, la première étape est de prendre en considération les{' '}
                <strong>sanctions administratives</strong>. En effet, ces
                dernières vont survenir avant qu’un verdict soit rendu et
                pourraient tout de même être lourdes de conséquences.{' '}
            </p>
            <p>
                En d’autres mots, peu importe si vous êtes reconnu coupable ou
                non d’avoir conduit sous l’effet de l’alcool, la{' '}
                <strong>SAAQ peut imposer des sanctions</strong> au conducteur.
                Cela se fait dès que les résultats de l’alcootest démontrent que
                vous êtes au-dessus de la limite de 80 mg d’alcool pour 100 ml
                de sang.
            </p>
            <h3>La suspension de votre permis de conduire (90 jours)</h3>
            <p>
                Tout d’abord, la première conséquence lorsque vous êtes
                intercepté au volant d’un véhicule en état d’ébriété est la{' '}
                <strong>suspension de votre permis de conduire</strong>. Cette
                suspension aura une durée de 90 jours.
            </p>
            <p>
                Il est important de savoir que cette sanction va s’appliquer,
                peu importe si vous en êtes à votre première infraction d’alcool
                au volant ou si vous êtes en récidive. En d’autres termes, votre
                permis sera automatiquement suspendu.
            </p>
            <p>
                De plus, puisqu’il s’agit d'une suspension
                <strong> ferme de 90 jours</strong>, vous aurez l’interdiction
                totale de conduire. Il ne sera pas possible de demander
                l’obtention d’un permis de conduire restreint ou toute autre
                mesure vous permettant de conduire le véhicule.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Attention!</strong> La SAAQ peut prendre la
                            décision de{' '}
                            <strong>
                                prolonger la suspension de votre permis
                            </strong>
                            . Cela est particulièrement le cas lorsque vous êtes
                            à risque de reproduire les gestes. Par conséquent,
                            si vous en êtes à votre première infraction et que
                            vous ne faites pas preuve d’inconscience, il est
                            probable que cela ne vous concerne pas.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Qu’arrive-t-il à la fin des 90 jours?</strong> Lorsque
                le délai arrive à sa fin, votre permis de conduire vous sera
                automatiquement remis jusqu’à ce que vous ayez à vous présenter
                devant le tribunal. Dans l’éventualité où vous êtes reconnu
                coupable, vous serez de nouveau soumis à une interdiction de
                conduire.{' '}
            </p>
            <h3>Le Programme d’évaluation du risque (SAAQ)</h3>
            <p>
                Lorsque vous êtes intercepté par un policier en état d’ébriété
                alors que vous conduisiez, il est possible que vous soyez
                contraint de suivre le{' '}
                <strong>
                    Programme d’évaluation des risques de l’alcool au volant
                </strong>
                .{' '}
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Quelles sont les conséquences administratives de l’alcool au volant?"
                    alt=""
                />
            </p>
            <p>
                Ce programme a comme objectif de s’assurer que vos habitudes de
                consommation ne posent pas un risque pour votre conduite. Ainsi,
                il sera possible de déterminer si vous devriez garder votre
                permis de conduire ou non.{' '}
            </p>
            <p>
                Si vous en êtes <strong>à votre première infraction</strong>, il
                est rare que ce programme vous soit ordonné. Cependant, dans
                l’éventualité où votre taux d’alcool est au-dessus du double de
                la limite, il sera obligatoire de suivre le programme.{' '}
            </p>
            <p>
                Cela est également le cas lorsque vous en êtes à votre deuxième
                ou troisième infraction ou si vous refusez tout simplement de
                souffler dans l’alcootest. Lorsque vous devez suivre ce
                programme, le <strong>succès de l’évaluation</strong> sera
                nécessaire afin d’avoir la restitution de votre permis dans les
                90 jours de suspension.
            </p>
            <h3>La saisie de votre véhicule</h3>
            <p>
                Ensuite, selon votre situation, il est possible que les
                accusations pour alcool au volant s'accompagnent de la{' '}
                <strong>saisie de votre véhicule. </strong>Cependant, il est
                important de savoir que cette sanction ne s’applique
                généralement qu’en cas de récidive. Par conséquent, si vous en
                êtes à votre première infraction, cela ne risque pas de se
                produire!
            </p>
            <p>
                En revanche, si vous en êtes à votre première infraction, il est
                possible que vous fassiez face à une ordonnance de saisie de
                votre véhicule si vous vous retrouvez dans l’une des deux
                situations suivantes:
            </p>
            <ul>
                <li>Vous refusez de souffler dans l’alcootest, ou </li>
                <li>
                    Vous dépassez le double de la limite d’alcool permise au
                    volant.
                </li>
            </ul>
            <p>
                Si vous êtes dans l’une de ces situations, il est possible que
                votre véhicule soit saisi pour une durée d’environ 30 jours. Peu
                importe la durée de la saisie, il est important de savoir que
                vous serez responsable <strong>des frais de remorquage </strong>
                et d’entreposage de votre véhicule pour toute sa durée.{' '}
            </p>
            <h2>
                Quelles sont les sanctions criminelles de l’alcool au volant?
            </h2>
            <p>
                Outre les sanctions administratives imposées par la SAAQ,
                l’infraction d’alcool au volant est également assujettie à des{' '}
                <strong>conséquences criminelles</strong>. Toutefois, dans ce
                cas, vous devrez avoir été reconnu coupable de l’infraction.
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Que faire si je suis reconnu coupable d’alcool au volant au Québec?"
                    alt=""
                />
            </p>
            <p>
                De plus, pour établir les sanctions en question, il sera
                nécessaire de prendre en considération plusieurs facteurs qui
                peuvent <strong>aggraver les peines</strong> qui vous seront
                imposées. Par exemple, cela sera le cas d’une récidive. En
                revanche, dans le cas d’une première infraction, le tribunal est
                généralement moins sévère.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Infraction</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Première</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Deuxième</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Troisième</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Interdiction de conduire</p>
                    </td>
                    <td>
                        <p>Entre 1 et 3 ans</p>
                    </td>
                    <td>
                        <p>Entre 2 et 10 ans</p>
                    </td>
                    <td>
                        <p>Au moins 3 ans</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Interdiction de conduire absolue</p>
                    </td>
                    <td>
                        <p>N/A</p>
                    </td>
                    <td>
                        <p>3 mois</p>
                    </td>
                    <td>
                        <p>6 mois</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Amende minimale</p>
                    </td>
                    <td>
                        <p>Entre 1 000$ et 2 000$</p>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <p>Possibilité d’antidémarreur éthylométrique</p>
                    </td>
                    <td>
                        <p>Oui</p>
                    </td>
                    <td>
                        <p>Oui</p>
                    </td>
                    <td>
                        <p>Oui</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Emprisonnement minimal</p>
                    </td>
                    <td>
                        <p>N/A</p>
                    </td>
                    <td>
                        <p>30 jours</p>
                    </td>
                    <td>
                        <p>120 jours</p>
                    </td>
                </tr>
            </table>
            <p>
                Si vous en êtes à votre <strong>première infraction</strong>{' '}
                d’alcool au volant, il est important de noter que la
                contravention minimale va varier en fonction de votre taux
                d’alcoolémie. Ainsi, voici le montant que vous devrez payer:
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Taux d’alcoolémie</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Contravention à payer</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Entre 80 mg et 119 mg</p>
                    </td>
                    <td>
                        <p>1 000$</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Entre 120 mg et 159 mg</p>
                    </td>
                    <td>
                        <p>1 500$</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>160 mg et plus</p>
                    </td>
                    <td>
                        <p>2 000$</p>
                    </td>
                </tr>
            </table>
            <p>
                Puisque ces sanctions seront imposées par un juge après avoir
                été reconnues coupables d’alcool au volant, il est important de
                faire appel à un avocat en droit criminel afin que celui-ci
                puisse vous représenter et mettre en place votre défense.
            </p>
            <p>
                <strong>
                    Heureusement, grâce à Avocats Alcool au Volant, il vous sera
                    possible de trouver le bon avocat gratuitement!
                </strong>
            </p>
            <h2>
                Trouvez l’avocat qu’il vous faut dès aujourd’hui grâce à Avocats
                Alcool au Volant
            </h2>
            <p>
                Ainsi, lorsque vous êtes accusé d’avoir conduit en état
                d’ébriété, il est important d’avoir une bonne défense, et ce,
                même si vous en êtes à votre première infraction. Pour ce faire,
                rien de mieux que de faire appel à un avocat spécialisé en droit
                criminel.
            </p>
            <p>
                Face à de telles accusations,{' '}
                <Link to="https://avocatsalcoolauvolant.ca/defenses-alcool-volant/">
                    les possibilités
                </Link>{' '}
                de défense sont assez limitées. En effet, si vous souhaitez vous
                en sortir indemne, il sera nécessaire d’orienter votre défense
                sur l’un des points suivants:
            </p>
            <ul>
                <li>La crédibilité de l’alcootest,</li>
                <li>
                    La violation d’un droit fondamental au moment de
                    l’arrestation, ou{' '}
                </li>
                <li>Les délais dans la prise d'échantillons.</li>
            </ul>
            <p>
                Après tout, un simple écart de conduite ne devrait pas
                nécessairement engendrer des conséquences qui vont vous suivre
                toute votre vie. Pour ce faire, il faut être en mesure d'en
                trouver le bon professionnel.{' '}
            </p>
            <p>
                <strong>Comment faire?</strong>{' '}
                <strong>
                    Vous n’avez qu’à remplir le formulaire qui se trouve en bas
                    de page, et on se charge de trouver le bon avocat spécialisé
                    en droit criminel près de chez vous, et ce, complètement
                    gratuitement.
                </strong>
            </p>
            <p>
                <strong>
                    N’attendez plus et remplissez le formulaire d’Avocats Alcool
                    au Volant dès maintenant pour trouver le bon professionnel!
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "premiere-infraction-alcool-volant/alcool-volant-infraction.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "premiere-infraction-alcool-volant/alcool-volant-consequences.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "premiere-infraction-alcool-volant/alcool-volant-administrative.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "premiere-infraction-alcool-volant/alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
